<template>
   <b-modal
    id="usuarios_create"
    :title="'Crear '+nombre_rol"
    centered
    size="xl"
    cancel-title="Cancelar"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <usuario-form-modal
      :btnSubmit="'Crear '+nombre_rol"
      :usuario="data"
      @processForm="agregar"
    />
   </b-modal>
</template>

<script>
import {
  BModal, VBModal,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import usuarioFormModal from './UsuariosFormModal.vue'

export default {
  components: {
    BModal,
    VBModal,
    usuarioFormModal,
  },
  directives: {
    Ripple,
  },
  props: {
    nombre_rol: {
      type: String,
      default: 'Crear Usuario',
    },
    //* si no viene id_rol,
    //* Muestra v-select para seleccionar cualquier rol de usuario.
    id_rol: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      data: {
        rut: '',
        dv: '',
        nombre: '',
        primer_apellido: '',
        segundo_apellido: '',
        nombre_calle: '',
        numero: '',
        block: '',
        departamento: '',
        celular: '',
        correo: '',
        id_establecimiento: null,
        id_rol: null,
      },
    }
  },
  mounted() {
    this.cargaRol()
  },
  methods: {
    ...mapActions({
      createPersona: 'personas/addPersona',
      createUsuario: 'usuarios/addUsuario'
     }),
    cargaRol() {

      this.data.id_rol = this.id_rol
    },
    agregar(usuario) {
      this.createPersona(usuario).then(() => {
        const statusPersonas = store.state.personas.status
        if (statusPersonas === 'success') {

          const rut = usuario.rut.substring(0, 5)
          const id_persona = store.state.personas.id_persona
          const data = {
            id_persona,
            password: '12345',
          }

          this.createUsuario(data).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.nombre_rol+' guardado 👍',
                icon: 'CheckIcon',
                text: 'La clave de acceso del nuevo '+this.nombre_rol+', es " 12345 "!',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
              timeout: 6000,
            })
          })
          this.$emit('reload')
          this.$bvModal.hide('usuarios_create')
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.personas.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    // closeModal() {
    // },
    // openModal() {
    //   // if (typeof this.diaSelected.dia !== 'undefined') {
    //   //   this.cargarAsistencias(this.getLibroSelected.id)
    //   // }
    // },
  },
}

</script>
