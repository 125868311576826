<template>
  <b-overlay
    :show="!cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-card
      aria-hidden="true"
      class="mb-0"
    >
      <!-- Form: USUARIO -->
      <b-row class="mt-1 pl-25 pr-25  pt-25 text-left">

        <b-col
          cols="12"
          md="2"
          class="mb-25"
        >
          <b-card-sub-title>
            Reemplazar Docente
          </b-card-sub-title>
        </b-col>
        <b-col
          cols="12"
          md="10"
          class="mb-25"
        >
          <b-card-sub-title>
            <hr class="mt-75">
          </b-card-sub-title>
        </b-col>

        <!-- Field: DOCENTE REEMPLAZADO -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Docente reemplazado *"
            label-for="id_persona_rol_docente"
          >
            <v-select
              id="id_persona_rol_docente"
              v-model="reemplazo.id_persona_rol_docente"
              placeholder="Selecciona el docente reemplazado..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :reduce="option => option.id_persona_rol_docente"
              :options="optionsDocente"
              :class="v$.reemplazo.id_persona_rol_docente.$error === true
                ? 'border-danger rounded'
                : ''"
            />
            <div
              v-if="v$.reemplazo.id_persona_rol_docente.$error"
              id="id_persona_rol_docenteInfo"
              class="text-danger text-left"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.curso.id_persona_rol_docente.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </b-form-group>
        </b-col>

        <!-- Field: TIPO JORNADA -->
        <b-col
          cols="12"
          md="5"
        >
          <b-form-group
            label="Docente reemplazador *"
            label-for="id_persona_rol_reemplazo"
          >
            <v-select
              id="id_persona_rol_reemplazo"
              v-model="reemplazo.id_persona_rol_reemplazo"
              placeholder="Selecciona el docente reemplazador..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :reduce="option => option.id_persona_rol_reemplazo"
              :options="optionsReemplazo"
              :class="v$.reemplazo.id_persona_rol_reemplazo.$error === true
                ? 'border-danger rounded'
                : ''"
            />


            <div
              v-if="v$.reemplazo.id_persona_rol_reemplazo.$error"
              id="id_persona_rol_reemplazoInfo"
              class="text-danger text-left"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.curso.id_persona_rol_reemplazo.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="1"
        >
          <b-form-group>
            <b-button
              v-if="$can('create' , 'usuarios')"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.usuarios_create
              variant="primary"
              class="btn btn-icon mt-2"
              title="Crear Docente"
            >
              <feather-icon
                icon="UserPlusIcon"
              />
            </b-button>
            <usuarios-create-modal
              :id_rol="2"
              nombre_rol="Docente"
              @reload="cargaDocentes()"
            ></usuarios-create-modal>
          </b-form-group>
        </b-col>

        <!-- Field: fecha_inicio -->
        <b-col
          cols="6"
          md="6"
        >
          <b-form-group
            label="Fecha inicio *"
            label-for="fecha_inicio"
          >
            <b-form-datepicker
              v-model="reemplazo.fecha_inicio"
              id="fecha_inicio"
              :min="minInicio"
              :max="maxInicio"
              placeholder="Selecciona una fecha"
              hide-header
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short'
              }"
              start-weekday="1"
              locale="es-CL"
              :date-disabled-fn="dateDisabled"
              label-help=""
              :state="v$.reemplazo.fecha_inicio.$error === true
                ? false
                : null"
              @blur.native="v$.reemplazo.fecha_inicio.$touch"
              @input="changeFechaInicio"
            />
            <!-- Mensajes Error Validación -->
            <b-form-invalid-feedback
              v-if="v$.reemplazo.fecha_inicio.$error"
              id="fecha_inicioInfo"
              class="text-right"
            >
              <p v-for="error of v$.reemplazo.fecha_inicio.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Fecha_termino -->
        <b-col
          cols="6"
          md="6"
        >
          <b-form-group
            label="Fecha termino *"
            label-for="fecha_termino"
          >

            <b-form-datepicker
              v-model="reemplazo.fecha_termino"
              id="fecha_termino"
              placeholder="Selecciona una fecha"
              hide-header
              :min="minTermino"
              :max="maxTermino"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short'
              }"
              start-weekday="1"
              locale="es-CL"
              :date-disabled-fn="dateDisabled"
              label-help=""
              :state="v$.reemplazo.fecha_termino.$error === true
                ? false
                : null"
              @blur.native="v$.reemplazo.fecha_termino.$touch"
            />
            <!-- Mensajes Error Validación -->
            <b-form-invalid-feedback
              v-if="v$.reemplazo.fecha_termino.$error"
              id="abreviaturaInfo"
              class="text-right"
            >
              <p v-for="error of v$.reemplazo.fecha_termino.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>


      </b-row>

      <colLinea />

      <!-- Action Buttons -->
      <b-row>
        <b-col
          cols="12"
          md="10"
        >
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="text-right"
        >
          <btnSubmit
            variant="primary"
            :btnText="btnSubmit"
            :modulo="nombre_permiso"
            @processBtn="submitOption"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
// ETIQUETAS //
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BCard, BFormSelect, BInputGroup,
  BInputGroupPrepend, BCardSubTitle, BFormDatepicker
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import vSelect from 'vue-select'

import { mapGetters, mapActions, mapMutations } from 'vuex'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'


// COMPONENTS RECICLADOS //
import btnSubmit from '../../../components/Form/btnSubmit.vue'
import colLinea from '../../../components/Form/colLinea.vue'

import usuariosCreateModal from '../../Usuarios/components/Modal/UsuariosCreateModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    vSelect,
    BOverlay,
    BCard,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BCardSubTitle,
    BFormDatepicker,

    // COMPONENTS RECICLADOS //
    btnSubmit,
    colLinea,
    usuariosCreateModal,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cargando: true,
      nombre_permiso: 'reemplazos',

      minInicio: null,
      maxInicio: null,
      minTermino: null,
      maxTermino: null,

      optionsDocente: [],
      optionsReemplazo: [],
    }
  },
  computed: {
    ...mapGetters({
      getCalendarios: 'calendarios/getCalendarios',
      getPersonasRol: 'personas/getPersonasRol',
      getUser: 'auth/user',
    }),
  },
  watch:{
  },
  props: {
    reemplazo: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Reemplazo',
    },
  },
  validations() {
    return {
      reemplazo: {
        fecha_inicio: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_termino: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_persona_rol_docente: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_persona_rol_reemplazo: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  mounted() {
    this.cargaCalendario()
    this.cargaDocentes()
  },
  methods: {
    ...mapActions({
      fetchCalendariosEstablecimiento: 'calendarios/fetchCalendariosEstablecimiento',
      fetchPersonasRol: 'personas/fetchPersonasRol',
      attempt: 'auth/attempt',
    }),
    cargaDocentes() {
      const data = {
        id_establecimiento: this.getUser.id_establecimiento,
        id_rol: 2,
      }
      this.fetchPersonasRol(data).then(() => {
        this.optionsDocente = []
        this.optionsReemplazo = []
        this.getPersonasRol.forEach(persona => {
          const title = persona.nombre+' '+persona.primer_apellido+' '+persona.segundo_apellido
          this.optionsDocente.push({
            title,
            id_persona_rol_docente: persona.id_persona_rol
          })

          this.optionsReemplazo.push({
            title,
            id_persona_rol_reemplazo: persona.id_persona_rol
          })
        })
      })
    },
    cargaCalendario() {
      this.fetchCalendariosEstablecimiento(this.getUser.id_establecimiento).then(() => {
        this.minInicio = this.getCalendarios.fecha_inicio
        this.maxInicio = this.getCalendarios.fecha_termino
      })
    },
    submitOption() {
      this.v$.reemplazo.$touch()
      if (!this.v$.reemplazo.$invalid) {
        this.$emit('processForm', this.reemplazo)
      }
    },
    changeFechaInicio() {
      let fecha_inicio = new Date(this.reemplazo.fecha_inicio)
      fecha_inicio.setDate(fecha_inicio.getDate() + 2);
      this.reemplazo.fecha_termino = null
      this.minTermino = new Date(fecha_inicio)
      this.maxTermino = this.getCalendarios.fecha_termino
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 1
      return weekday === 0 || weekday === 6
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
