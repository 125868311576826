var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":!_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-card',{staticClass:"mb-0",attrs:{"aria-hidden":"true"}},[_c('b-row',{staticClass:"mt-1 pl-25 pr-25  pt-25 text-left"},[_c('b-col',{staticClass:"mb-25",attrs:{"cols":"12","md":"2"}},[_c('b-card-sub-title',[_vm._v(" Reemplazar Docente ")])],1),_c('b-col',{staticClass:"mb-25",attrs:{"cols":"12","md":"10"}},[_c('b-card-sub-title',[_c('hr',{staticClass:"mt-75"})])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Docente reemplazado *","label-for":"id_persona_rol_docente"}},[_c('v-select',{class:_vm.v$.reemplazo.id_persona_rol_docente.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"id":"id_persona_rol_docente","placeholder":"Selecciona el docente reemplazado...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","reduce":function (option) { return option.id_persona_rol_docente; },"options":_vm.optionsDocente},model:{value:(_vm.reemplazo.id_persona_rol_docente),callback:function ($$v) {_vm.$set(_vm.reemplazo, "id_persona_rol_docente", $$v)},expression:"reemplazo.id_persona_rol_docente"}}),(_vm.v$.reemplazo.id_persona_rol_docente.$error)?_c('div',{staticClass:"text-danger text-left",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_persona_rol_docenteInfo"}},_vm._l((_vm.v$.curso.id_persona_rol_docente.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"Docente reemplazador *","label-for":"id_persona_rol_reemplazo"}},[_c('v-select',{class:_vm.v$.reemplazo.id_persona_rol_reemplazo.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"id":"id_persona_rol_reemplazo","placeholder":"Selecciona el docente reemplazador...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","reduce":function (option) { return option.id_persona_rol_reemplazo; },"options":_vm.optionsReemplazo},model:{value:(_vm.reemplazo.id_persona_rol_reemplazo),callback:function ($$v) {_vm.$set(_vm.reemplazo, "id_persona_rol_reemplazo", $$v)},expression:"reemplazo.id_persona_rol_reemplazo"}}),(_vm.v$.reemplazo.id_persona_rol_reemplazo.$error)?_c('div',{staticClass:"text-danger text-left",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_persona_rol_reemplazoInfo"}},_vm._l((_vm.v$.curso.id_persona_rol_reemplazo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"1"}},[_c('b-form-group',[(_vm.$can('create' , 'usuarios'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.usuarios_create",modifiers:{"usuarios_create":true}}],staticClass:"btn btn-icon mt-2",attrs:{"variant":"primary","title":"Crear Docente"}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}})],1):_vm._e(),_c('usuarios-create-modal',{attrs:{"id_rol":2,"nombre_rol":"Docente"},on:{"reload":function($event){return _vm.cargaDocentes()}}})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha inicio *","label-for":"fecha_inicio"}},[_c('b-form-datepicker',{attrs:{"id":"fecha_inicio","min":_vm.minInicio,"max":_vm.maxInicio,"placeholder":"Selecciona una fecha","hide-header":"","date-format-options":{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short'
            },"start-weekday":"1","locale":"es-CL","date-disabled-fn":_vm.dateDisabled,"label-help":"","state":_vm.v$.reemplazo.fecha_inicio.$error === true
              ? false
              : null},on:{"input":_vm.changeFechaInicio},nativeOn:{"blur":function($event){return _vm.v$.reemplazo.fecha_inicio.$touch($event)}},model:{value:(_vm.reemplazo.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.reemplazo, "fecha_inicio", $$v)},expression:"reemplazo.fecha_inicio"}}),(_vm.v$.reemplazo.fecha_inicio.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"fecha_inicioInfo"}},_vm._l((_vm.v$.reemplazo.fecha_inicio.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha termino *","label-for":"fecha_termino"}},[_c('b-form-datepicker',{attrs:{"id":"fecha_termino","placeholder":"Selecciona una fecha","hide-header":"","min":_vm.minTermino,"max":_vm.maxTermino,"date-format-options":{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short'
            },"start-weekday":"1","locale":"es-CL","date-disabled-fn":_vm.dateDisabled,"label-help":"","state":_vm.v$.reemplazo.fecha_termino.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.reemplazo.fecha_termino.$touch($event)}},model:{value:(_vm.reemplazo.fecha_termino),callback:function ($$v) {_vm.$set(_vm.reemplazo, "fecha_termino", $$v)},expression:"reemplazo.fecha_termino"}}),(_vm.v$.reemplazo.fecha_termino.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"abreviaturaInfo"}},_vm._l((_vm.v$.reemplazo.fecha_termino.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"10"}}),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12","md":"2"}},[_c('btnSubmit',{attrs:{"variant":"primary","btnText":_vm.btnSubmit,"modulo":_vm.nombre_permiso},on:{"processBtn":_vm.submitOption}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }